export default class HeroMeshData {
  constructor(
    uTime,
    rY,
    rX,
    textureDir,
    uTexture,
    positionX,
    positionY,
    positionZ,
    rotationX,
    rotationY,
    rotationZ,
    vertexON,
    bgColor
  ) {
    this.uTime = uTime;
    this.rX = rX;
    this.rY = rY;
    this.textureDir = textureDir;
    this.uTexture = uTexture;
    this.positionX = positionX;
    this.positionY = positionY;
    this.positionZ = positionZ;
    this.rotationX = rotationX;
    this.rotationY = rotationY;
    this.rotationZ = rotationZ;
    this.vertexON = vertexON;
    this.bgColor = bgColor;
  }
}
