import  '../styles.css';
import * as THREE from "three";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import HeroMesh from "./view/HeroMesh";
import HeroMeshData from "./model/HeroMeshData";
 
/*Assets*/
import texture1 from "../images/text1.png";
import texture2 from "../images/text2.png";
import texture3 from "../images/text3.png";
import texture4 from "../images/text4.png";
import texture5 from "../images/text5.png";
 /*Register GSAP Plugins*/
gsap.registerPlugin(SplitText);
gsap.registerPlugin(ScrollTrigger);
 
/*Declar Vars-Consts*/
let isActive = false;
let camera, scene, renderer, bg, bodyScrollBar;
let currentObject, objects;
let currentIndex = 0;
let tTexture1, tTexture2, tTexture3, tTexture4, tTexture5;
let currentColor, currentBgColor;
const loader = new THREE.TextureLoader();
const clock = new THREE.Clock();
let timeouts = [];

if (window.cpage == "home") {
  // initPageTransitions();
  init();
  animate();
  initSmoothScrollbar();
} else {
  initSmoothScrollbar();
  hideLoader();
  document.querySelector("header").classList.add("header-scroll");
}

function init() {
  renderer = new THREE.WebGLRenderer({ transpernt: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  bg = document.querySelector(".bg-holder");
  bg.prepend(renderer.domElement);

  // Camera
  camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    0.1,
    100
  );

  // Scene
  scene = new THREE.Scene();

  // Textures
  loadTextures();

  // Mesh
  const currentObjectData1 = new HeroMeshData(
    clock,
    18.0,
    45.0,
    1,
    tTexture2,
    0,
    0,
    -25,
    0,
    -6.3,
    -0.1,
    true,
    new THREE.Color("#003cb5")
  );
  const currentObjectData2 = new HeroMeshData(
    clock,
    15.0,
    15.0,
    1,
    tTexture1,
    10,
    0,
    -25,
    0,
    -1.5,
    -0.1,
    true,
    new THREE.Color("#003cb5")
  );

  const currentObjectData3 = new HeroMeshData(
    clock,
    20.0,
    10.0,
    1,
    tTexture3,
    11,
    0,
    -25,
    0,
    -1.05,
    -0.1,
    true,
    new THREE.Color("#003cb5")
  );
  const currentObjectData4 = new HeroMeshData(
    clock,
    15.0,
    10.0,
    1,
    tTexture4,
    -2,
    0,
    -25,
    5.5,
    -0.9,
    0.1,
    false,
    new THREE.Color("#003cb5")
  );

  const currentObjectData5 = new HeroMeshData(
    clock,
    30.0,
    30.0,
    1,
    tTexture5,
    7,
    0,
    -25,
    0,
    0.3,
    -0.05,
    false,
    new THREE.Color("#003cb5")
  );

  objects = [];
  let obj1 = new HeroMesh(scene, currentObjectData2, "type2");
  obj1.createMesh();
  objects.push(obj1);

  let obj2 = new HeroMesh(scene, currentObjectData3, "type3");
  obj2.createMesh();
  objects.push(obj2);

  let obj3 = new HeroMesh(scene, currentObjectData1, "type1");
  obj3.createMesh();
  objects.push(obj3);

  let obj4 = new HeroMesh(scene, currentObjectData4, "type4");
  obj4.createMesh();
  objects.push(obj4);

  let obj5 = new HeroMesh(scene, currentObjectData5, "type5");
  obj5.createMesh();
  objects.push(obj5);

  currentObject = obj1;
  obj1.animateIn();

  // Events
  window.scrollTo(0, 1);

  onWindowResize();
  window.addEventListener("resize", onWindowResize, false);

  hideLoader();
}
// Active
window.addEventListener("blur", () => {
  isActive = true;
  timeouts.forEach((element) => {
    clearTimeout(element);
  });
});

// Inactive
window.addEventListener("focus", () => {
  isActive = false;
  window.scrollTo(0, 1);
  timeouts.forEach((element) => {
    clearTimeout(element);
  });
  if (window.cpage == "home") {
    slide();
  }
});

function slide() {
  if (isActive) return;
  timeouts.forEach((element) => {
    clearTimeout(element);
  });
  let timeout = setTimeout(() => {
    currentIndex++;
    currentIndex = currentIndex == 5 ? 0 : currentIndex;
    currentObject.animateOut();

    currentObject = objects[currentIndex];
    currentObject.animateIn();
    slide();
  }, 7000);
  timeouts.push(timeout);
}

function onWindowResize() {
  let width = window.innerWidth;
  let height = window.innerHeight;
  camera.aspect = width / height;
  camera.updateProjectionMatrix();
  renderer.setSize(width, height);
  setTimeout(() => {
    window.scrollTo(0, 1);
  }, 1000);
}

function animate() {
  requestAnimationFrame(animate);
  render();
}

function render() {
  if (isActive && bodyScrollBar.offset.y > window.innerHeight) return;
  if (currentObject) currentObject.update();
  renderer.render(scene, camera);
}

function resetBodyColor() {
  if (window.cpage == "home" && bodyScrollBar.offset.y <= window.innerHeight + 50) {
    document.documentElement.style.setProperty("--bgColor", window.cpageBgColro);
    document.documentElement.style.setProperty("--textColor", window.cpageColor);
    objects.forEach(function (element) {
      element.updateColor(new THREE.Color(window.cpageBgColro));
    });
  }

  if (window.cpage == "home" && bodyScrollBar.offset.y <= window.innerHeight + 50) {
    document.documentElement.style.setProperty("--bgColor", window.cpageBgColro);
    document.documentElement.style.setProperty("--textColor", window.cpageColor);

     gsap.to(".overlay", {
      duration: 0.5,
      opacity: 0.9
    });

    document.querySelector("header").classList.remove("header-scroll");

     gsap.to("canvas", {
      duration: 0.5,
      opacity: 1,
      onStart: function onStart() {
        isActive = false;
      }
    });
  }
}

// Smoooth Scrollbar
function initSmoothScrollbar() {
  var fixedElem = document.querySelector("header");
  bodyScrollBar = window.Scrollbar.init(document.querySelector("#viewport"), {
    damping: 0.035,
  }); 
  bodyScrollBar.setPosition(0, 0);
  bodyScrollBar.track.xAxis.element.remove();
  ScrollTrigger.scrollerProxy(document.body, {
    scrollTop(value) {
      if (arguments.length) { 
        bodyScrollBar.scrollTop = value;
      }
      return bodyScrollBar.scrollTop;
    },
  });
  bodyScrollBar.addListener(ScrollTrigger.update);
  bodyScrollBar.addListener(function (status) {
    var offset = status.offset;

    fixedElem.style.top = offset.y + "px";
    fixedElem.style.left = offset.x + "px";
    if (window.cpage == "home") {
      bg.style.top = offset.y + "px";
      bg.style.left = offset.x + "px";
    }
  });

  gsap.utils.toArray(".project").forEach((stage   ) => {
    ScrollTrigger.create({
      trigger: stage,
      start: "top center ",
      end: () => `+=${stage.clientHeight + getVh() / 50}`,
      onEnter: () => {
        currentColor = stage.dataset.txt;
        currentBgColor = stage.dataset.color;
        updateBodyColor(currentBgColor, currentColor);
      },
      onEnterBack: () => {
        currentColor = stage.dataset.txt;
        currentBgColor = stage.dataset.color;
        updateBodyColor(currentBgColor, currentColor);
      },
    });
  });

  ScrollTrigger.create({
    trigger: ".about",
    start: "top bottom",
    onEnter: () => resetBodyColor(),
    onEnterBack: () => resetBodyColor(),
  });

  ScrollTrigger.create({
    trigger: "#imgs",
    start: "top center",
    onEnter: () => resetBodyColor(),
    onEnterBack: () => resetBodyColor(),
  });

  ScrollTrigger.create({
    trigger: ".services",
    start: "top bottom",

    onEnter: () => {
      if (window.cpage == "home") {
        updateBodyColor("#ffffff", "#000000");
      }
    },
    onEnterBack: () => {
      if (window.cpage == "home") {
        updateBodyColor("#ffffff", "#000000");
      }
    },
  });

  ScrollTrigger.create({
    trigger: ".clients-title",
    start: "top bottom",
    onEnter: () => {
      updateBodyColor("#ffffff", "#000000");
    },
  });

  ScrollTrigger.create({
    trigger: ".ctitle",
    start: "top bottom",
    onEnter: () => {
      updateBodyColor("#333333", "#ffffff");
    },
  });

  ScrollTrigger.create({
    trigger: ".projects",
    start: "top bottom",

    onEnter: () => {
      isActive = false;
    },
  });
  const titles = gsap.utils.toArray(".title");
  titles.forEach((el) => {
    gsap.from(el, 1.5, {
      scrollTrigger: {
        trigger: el,
        start: "top bottom",
      },
      ease: "power3.out",
      rotation: 15,
      delay: 2.5,
      opacity: 0,
      y: 200,
    });
  });

  const clients = gsap.utils.toArray(".clients li");
  clients.forEach((el) => {
    gsap.from(el, 1.5, {
      scrollTrigger: {
        trigger: ".clients",
        start: "top center",
      },
      ease: "power3.out",
      rotation: 15,
      stagger: 0.3,
      delay: 1.7,
      scaleX: 0.9,
      scaleY: 1.9,
      opacity: 0,
      y: 100,
    });
  });

  const all = gsap.utils.toArray(".par");
  all.forEach((el) => {
    gsap.from(el, 1.5, {
      scrollTrigger: {
        trigger: el,
        start: "top center",
      },
      ease: "power3.out",
      rotation: 10,
      stagger: 0.3,
      delay: 1.5,
      opacity: 0,
      y: 100,
    });
  });

  const contactp = gsap.utils.toArray(".contact p");
  contactp.forEach((el) => {
    gsap.from(el, 2.5, {
      scrollTrigger: {
        trigger: el,
        start: "top bottom",
      },
      ease: "power3.out",
      rotation: 10,
      stagger: 0.5,
      delay: 1.5,
      opacity: 0,
      y: 100,
    });
  });
  const projectsHeader = gsap.utils.toArray(".project h1");
  projectsHeader.forEach((el) => {
    gsap.from(el, 1.5, {
      scrollTrigger: {
        trigger: el,
        start: "top bottom",
      },
      ease: "power3.out",
      rotation: 10,
      stagger: 0.3,
      delay: 0.5,
      opacity: 0,
      y: 100,
    });
  });
  const projects = gsap.utils.toArray(".project");
  projects.forEach((el) => {
    gsap.from(el, 1.5, {
      scrollTrigger: {
        trigger: el,
        start: "top center",
      },
      ease: "power3.out",
      rotation: 10,
      stagger: 0.3,
      delay: 1.5,
      opacity: 0,
      y: 100,
    });

    var par = el.querySelector("p");
    gsap.from(par, 2.5, {
      scrollTrigger: {
        trigger: el,
        start: "top center",
      },
      ease: "power3.out",
      rotation: 10,
      stagger: 0.3,
      delay: 3,
      opacity: 0,
      y: 100,
    });

    var img = el.querySelector("img");
    gsap.from(img, 1.5, {
      scrollTrigger: {
        trigger: el,
        start: "top center",
      },
      ease: "power3.out",
      scaleX: 0.9,
      scaleY: 1.9,
      delay: 1,
      y: 100,
    });
  });
  setTimeout(() => {
    window.scrollTo(0, 1);
  }, 1000);

  if (window.cpage == "home") {
    const urlParams = new URLSearchParams(window.location.search);
    const pageParam = urlParams.get("p");
    if (pageParam == "work") {
      gotoSection(pageParam, 100);
    } else if (pageParam == "studio") {
      gotoSection(pageParam, 50);
    }
  }
}

function updateBodyColor(color, txtCol) {
  document.documentElement.style.setProperty("--bgColor", color);
  document.documentElement.style.setProperty("--textColor", txtCol);
  var col = color;
  if (col == "#ffffff") col = "#000000";

  if (window.cpage == "home") {
    objects.forEach(function (element) {
      element.updateColor(new THREE.Color(col));
    });
  }

  if (window.cpage == "home") {
    gsap.to(".overlay", {
      duration: 0.3,
      opacity: 1
    });

    document.querySelector("header").classList.add("header-scroll");

     gsap.to("canvas", {
      duration: 0.3,
      opacity: 0,
      onComplete: function onComplete() {
        isActive = true;
      }
    });
  }
}


function getVh() {
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  return vh;
}

//3D Textures
function loadTextures() {
  tTexture1 = loader.load(texture1, function (texture) {
    texture.minFilter = THREE.NearestFilter;
  });
  tTexture2 = loader.load(texture2, function (texture) {
    texture.minFilter = THREE.NearestFilter;
  });
  tTexture3 = loader.load(texture3, function (texture) {
    texture.minFilter = THREE.NearestFilter;
  });
  tTexture4 = loader.load(texture4, function (texture) {
    texture.minFilter = THREE.NearestFilter;
  });
  tTexture5 = loader.load(texture5, function (texture) {
    texture.minFilter = THREE.NearestFilter;
  });
}

function animationText() {
  const scrollSplitText = new SplitText(".scroll-down");
  document.querySelector(".scroll-down").style.display = "flex";
  gsap.from(scrollSplitText.words, 0.5, {
    repeatDelay: 1,
    yoyo: true,
    yoyoEase: true,
    repeat: -1,
    stagger: 0.07,
    opacity: 0,
    y: -20,
    ease: "Back.easeInOut",
  }); 
}

function hideLoader() {
  const loader = document.querySelector(".loader-container");
  let delayMod = 0.01;

  if (window.cpage == "home") {
    delayMod = 1;
    objects[0].animateOutInit();
  } else {
    setTimeout(() => {
      updateBodyColor(window.cpageBgColro, window.cpageColor);
    }, 100);
  }

  gsap.to(loader, 0.5, {
    y: -window.innerHeight + 100,
    opacity: 0,
    delay: 1.5,
    onComplete: () => {
      loader.style.display = "none";
      gsap.to(".about-title span", 1.2, {
        top: 0,
        ease: "power3.out",
        rotation: 0,
        stagger: 0.3,
        opacity: 1,
        delay: 1.5 * delayMod,
      });

      gsap.to("header>div", 1, {
        opacity: 1,
        top: 0,
      });

      gsap.to("header ul", 1, {
        opacity: 1,
        delay: 0.5 * delayMod,
      });

      gsap.to(".about-title p", 1, {
        opacity: 1,
        delay: 2.5 * delayMod,
      });

      gsap.to(".about p", 1.2, {
        top: 0,
        ease: "power3.out",
        rotation: 0,
        stagger: 0.3,
        opacity: 1,
        delay: 2.5 * delayMod,
      });

      if (window.cpage == "home") {
        objects[0].animateIn();
        setTimeout(() => {
          slide();
          animationText();
        }, 3000);
      }
      document.getElementById("contect_link").addEventListener(
        "click",
        function (event) {
          event.preventDefault();

          gotoSection("contact-us", 50);
        },
        false
      );
      if (window.cpage == "home") {
        document.getElementById("studio_link").addEventListener(
          "click",
          function (event) {
            event.preventDefault();

            gotoSection("studio", 50);
          },
          false
        );

        document.getElementById("work_link").addEventListener(
          "click",
          function (event) {
            event.preventDefault();

            gotoSection("work", 100);
          },
          false
        );

        document.querySelector(".logo").addEventListener(
          "click",
          function (event) {
            event.preventDefault();
            setTimeout(() => {
              resetBodyColor();
            }, 3000);
            gotoSection("about", 0);
          },
          false
        );
        document.querySelector(".logo-txt").addEventListener(
          "click",
          function (event) {
            event.preventDefault();

            gotoSection("about", 0);
          },
          false
        );
      }
    },
  });
}
window.addEventListener("orientationchange", function ( ) {
  setTimeout(() => {
    window.scrollTo(0, 1);
  }, 1000);
});
function gotoSection(id, offset) {
  bodyScrollBar.scrollIntoView(document.getElementById(id), {
    offsetTop: offset,
  });
}

 