import * as THREE from "three";
import gsap from "gsap";

var   fragmentShader  =  `varying vec2 vUv;
varying vec3 vNormal;
varying vec3 vPos;

uniform float uTime;
uniform float rX;
uniform float rY;
uniform float textureDir;
uniform sampler2D uTexture;
uniform vec3 bgColor;

void main() {
  float shadow = clamp(vPos.z / 10.   , vPos.z / 10., vPos.z / 10.);
  vec2 uv = vUv;
  uv.x *= textureDir;
  uv.y += sin(uv.y * 0.25);
  vec2 repeat = vec2(rX, rY);
  
  uv = fract(uv * repeat + vec2( uTime/1.5, uTime * 2. ));
   vec4 color = texture2D(uTexture, uv);
   if (color.a <= 0.01) {
    vec4 fill = vec4(bgColor , 1.0 );
    color = (color * color.a) + (fill * (1.0 - color.a));
   }  
  gl_FragColor = color * vec4(vec3(shadow) - vec3(0.2,0.2,0.2)  ,1. );
}`;

var   vertexShader = `varying vec2 vUv;
varying vec3 vNormal;
varying mat4 vModelMatrix;
varying vec3 vPos;
uniform float uTime;
uniform bool vertexON;

vec3 mod289(vec3 x)
{
  return x - floor(x * (1.0 / 289.0)) * 289.0;
}

vec4 mod289(vec4 x)
{
  return x - floor(x * (1.0 / 289.0)) * 289.0;
}

vec4 permute(vec4 x)
{
  return mod289(((x*34.0)+1.0)*x);
}

vec4 taylorInvSqrt(vec4 r)
{
  return 1.79284291400159 - 0.85373472095314 * r;
}

vec3 fade(vec3 t) {
  return t*t*t*(t*(t*6.0-15.0)+10.0);
}

float pnoise(vec3 P, vec3 rep)
{
  vec3 Pi0 = mod(floor(P), rep); // Integer part, modulo period
  vec3 Pi1 = mod(Pi0 + vec3(1.0), rep); // Integer part + 1, mod period
  Pi0 = mod289(Pi0);
  Pi1 = mod289(Pi1);
  vec3 Pf0 = fract(P); // Fractional part for interpolation
  vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
  vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
  vec4 iy = vec4(Pi0.yy, Pi1.yy);
  vec4 iz0 = Pi0.zzzz;
  vec4 iz1 = Pi1.zzzz;

  vec4 ixy = permute(permute(ix) + iy);
  vec4 ixy0 = permute(ixy + iz0);
  vec4 ixy1 = permute(ixy + iz1);

  vec4 gx0 = ixy0 * (1.0 / 7.0);
  vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
  gx0 = fract(gx0);
  vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
  vec4 sz0 = step(gz0, vec4(0.0));
  gx0 -= sz0 * (step(0.0, gx0) - 0.5);
  gy0 -= sz0 * (step(0.0, gy0) - 0.5);

  vec4 gx1 = ixy1 * (1.0 / 7.0);
  vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
  gx1 = fract(gx1);
  vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
  vec4 sz1 = step(gz1, vec4(0.0));
  gx1 -= sz1 * (step(0.0, gx1) - 0.5);
  gy1 -= sz1 * (step(0.0, gy1) - 0.5);

  vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
  vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
  vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
  vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
  vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
  vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
  vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
  vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

  vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
  g000 *= norm0.x;
  g010 *= norm0.y;
  g100 *= norm0.z;
  g110 *= norm0.w;
  vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
  g001 *= norm1.x;
  g011 *= norm1.y;
  g101 *= norm1.z;
  g111 *= norm1.w;

  float n000 = dot(g000, Pf0);
  float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
  float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
  float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
  float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
  float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
  float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
  float n111 = dot(g111, Pf1);

  vec3 fade_xyz = fade(Pf0);
  vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
  vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
  float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x);
  return 2.2 * n_xyz;
}

void main() {
  vUv = uv;
  vNormal = normal;
  vModelMatrix = modelMatrix;
  vNormal = normal;
  vPos = position;
  if(!vertexON){
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
    return;
  }
   
  float time = uTime * 1.0;
  float t = uTime * .4;
  float distortion = pnoise((normal + t) * 1.3, vec3(7.0)) * 1.2;
  vec3 pos = position + (normal * distortion);
  
  gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.);
}`;
 export default class HeroMesh {
  constructor(scene, data, type) {
    this.data = data;
    this.type = type;
    this.scene = scene;
    this.geometry;
    this.material;
    this.mesh;
    this.uniforms;
  }

  createMesh() {
    this.uniforms = {};
    this.uniforms.uTime = { value: 0 };
    this.uniforms.textureDir = { value: this.data.textureDir };
    this.uniforms.rX = { value: this.data.rX };
    this.uniforms.rY = { value: this.data.rY };
    this.uniforms.uTexture = { value: this.data.uTexture };
    this.uniforms.vertexON = { value: this.data.vertexON };
    this.uniforms.bgColor = { value: this.data.bgColor };

    // Geometry
    if (this.type == "type1") {
      this.geometry = new THREE.TorusKnotGeometry(11, 5, 150, 75, 3, 2);
    } else if (this.type == "type2") {
      this.geometry = new THREE.SphereGeometry(13, 82, 82);
    } else if (this.type == "type3") {
      this.geometry = new THREE.TorusGeometry(12, 10, 50, 50);
    } else if (this.type == "type4") {
      this.geometry = new THREE.BoxGeometry(60, 25, 25);
    } else if (this.type == "type5") {
      this.geometry = new THREE.OctahedronGeometry(20, 0);
    }

    // Material
    this.material = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms: this.uniforms,
      side: THREE.DoubleSide,
    });

    // Mesh
    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.mesh.position.x = this.data.positionX;
    this.mesh.position.y = this.data.positionY;
    this.mesh.position.z = this.data.positionZ;
    this.mesh.rotation.x = this.data.rotationX;
    this.mesh.rotation.y = this.data.rotationY;
    this.mesh.rotation.z = this.data.rotationZ;
    this.mesh.visible = false;
    this.scene.add(this.mesh);
    this.mesh.position.x = 100;
  }

  updateColor(col) {
    this.data.bgColor = col;
    this.uniforms.bgColor = { value: col };
  }
  animateIn() {
    this.mesh.visible = true;
    this.mesh.position.x = 60;
    this.mesh.rotation.y = this.data.rotationY - 3;
    gsap.to(this.mesh.position, {
      duration: 1,
      x: this.data.positionX,
    });
    gsap.to(this.mesh.rotation, {
      duration: 1,
      y: this.data.rotationY,
    });
  }
  animateOutInit() {
    this.mesh.visible = false;
  }
  animateOut() {
    gsap.to(this.mesh.position, {
      duration: 1.5,
      /*   ease: "back.in(1.1)", */
      x: -60,
      onComplete: () => {
        this.mesh.visible = false;
      },
    });
    gsap.to(this.mesh.rotation, {
      duration: 1,
      y: this.data.rotationY + 3,
    });
  }

  update() {
    if (this.material.uniforms) {
      this.material.uniforms.uTime.value = this.data.uTime.getElapsedTime();
    }
  }
}
